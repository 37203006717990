import type { PillTagProps } from './PillTag.types';
import type { ComponentListProps } from '../ComponentList';

import { Pill as PillAndes } from '@andes/badge';
import classNames from 'classnames';

import { ComponentMapper } from '../ComponentMapper';
import { Image } from '../Image/mobile';
import { Icon } from '../Icon';

export const PillTag: React.FC<PillTagProps> = ({
  label,
  bgColor,
  color,
  rightContent,
  leftContent,
  icon,
  className,
  borderColor,
  ...props
}) => {
  const knownComponents: ComponentListProps['knownComponents'] = {
    image: Image,
    icon: Icon,
  };

  return (
    <PillAndes
      className={classNames(
        className,
        'pill-tag',
        `pill-tag-border--${borderColor}`,
      )}
      secondaryColor={{
        background: bgColor || '',
        text: color,
      }}
      {...props}
    >
      {icon && <Icon content={icon} className="pill-tag-icon" />}
      {leftContent ? (
        <ComponentMapper components={knownComponents} {...leftContent} />
      ) : null}
      <span style={{ color }}>{label}</span>
      {rightContent ? (
        <ComponentMapper components={knownComponents} {...rightContent} />
      ) : null}
    </PillAndes>
  );
};
