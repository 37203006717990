import type { FC } from 'react';
import type { SplashScreenData } from '../../../services/utils/validate-origin/validate-origin.type';

import ProgressIndicatorCircular from '@andes/progress-indicator-circular';

import { Image } from '../Image/mobile';
import { Typography } from '../../container/ErrorContainer/components';

export const SplashScreen: FC<SplashScreenData & { siteId: string }> = ({
  backdrop,
  logo,
  subtitle,
  icon,
  siteId,
}) => {
  const logoClassname =
    siteId === 'MLB' ? 'splash-screen__logo--mlb' : 'splash-screen__logo';

  return (
    <div
      className="splash-screen"
      style={{
        backgroundColor: backdrop.fallbackColor,
        backgroundImage: `url('${backdrop.image.props.url}')`,
      }}
    >
      <div className="splash-screen--gradient">
        <div className="splash-screen__brand">
          <Image {...logo.props} className={logoClassname} />
          <Typography {...subtitle.props} className="splash-screen__subtitle" />
          <ProgressIndicatorCircular
            className="splash-screen__spinner"
            {...icon.props}
          />
        </div>
      </div>
    </div>
  );
};
