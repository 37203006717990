import type { ContentSubtitleProps } from '../ContentSubtitle.types';

import classNames from 'classnames';

import { Typography } from '../../Typography/mobile';

export const ContentSubtitle: React.FC<ContentSubtitleProps> = ({
  subtitles,
  unit,
  className,
  size = 'xs',
}) => (
  <div className={classNames('content-subtitle', className)}>
    {subtitles.map((subtitle, idx) => (
      <Typography
        key={`${subtitle.props.label}-${idx.toString()}`}
        type="body"
        className="content-subtitle__label"
        size={size}
        unit={unit}
        {...subtitle.props}
      />
    ))}
  </div>
);
