import type { FC, SVGProps } from 'react';

export const RelevantIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 21.75L2.25 21.75V13.5108L21.75 13.5108V21.75ZM20.25 20.25V15.0108L3.75 15.0108V20.25L20.25 20.25Z"
      fill="currentColor"
      fillOpacity={0.55}
    />
    <path
      d="M2.25 6.01081L21.75 6.01081L21.75 7.51081L2.25 7.51081L2.25 6.01081Z"
      fill="currentColor"
      fillOpacity={0.55}
    />
    <path
      d="M21.75 9.75L2.25 9.75V11.25L21.75 11.25V9.75Z"
      fill="currentColor"
      fillOpacity={0.55}
    />
    <path
      d="M2.25 2.25L21.75 2.25V3.75L2.25 3.75V2.25Z"
      fill="currentColor"
      fillOpacity={0.55}
    />
  </svg>
);
