import type { FC, PropsWithChildren } from 'react';
import type { FeedItemProps } from './FeedItem.types';

import { useRef, useEffect } from 'react';

import classNames from 'classnames';

export const FeedItem: FC<PropsWithChildren<FeedItemProps>> = ({
  index,
  onMount,
  onBeforeUnmount,
  children,
  className,
}) => {
  const articleRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const element = articleRef.current;

    if (element) {
      onMount?.(element);
    }

    return () => {
      if (element) {
        onBeforeUnmount?.(element);
      }
    };
  }, [onBeforeUnmount, onMount]);

  return (
    <article
      className={classNames('feed__item', className)}
      data-index={index}
      ref={articleRef}
    >
      {children}
    </article>
  );
};
