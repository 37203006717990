import type { ContextState } from '../context/FeedState.types';
import type { Action, ActionHandler } from './feed.types';

import { FeedActions } from './feed.actionsTypes';
import { previewPlayerToggleAudio } from './actions/previewPlayerToggleAudio';
import { setInViewport } from './actions/setInViewport';
import { previewReadyToPlay } from './actions/previewReadyToPlay';
import { setPriorityPreview } from './actions/setPriorityPreview';

const actionsMap: {
  [type in FeedActions]: ActionHandler<type>;
} = {
  [FeedActions.PREVIEW_PLAYER_TOGGLE_AUDIO]: previewPlayerToggleAudio,
  [FeedActions.SET_IN_VIEWPORT]: setInViewport,
  [FeedActions.PREVIEW_READY_TO_PLAY]: previewReadyToPlay,
  [FeedActions.SET_PRIORITY_PREVIEW]: setPriorityPreview,
};

const getActionHandler = <T extends Action>(action: T) =>
  actionsMap[action.type] as (state: ContextState, action: T) => ContextState;

export default function FeedReducer(
  state: ContextState,
  action: Action,
): ContextState {
  return getActionHandler(action)(state, action);
}
