import type { ContentTagListProps } from '../ContentTagList.types';

import classNames from 'classnames';

import { Tag } from '../../Tag/mobile';

export const ContentTagList: React.FC<ContentTagListProps> = ({
  tags = [],
  className,
}) => (
  <div className={classNames('content-tag-list', className)}>
    {tags.map((tag) => (
      <Tag key={tag.props.label} size="small" {...tag.props} />
    ))}
  </div>
);
