import type { FC, PropsWithChildren } from 'react';
import type { SkeletonProps } from './Skeleton.types';

import classNames from 'classnames';

import { SkeletonColors, SkeletonVariants } from './Skeleton.types';

export const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
  children,
  color = SkeletonColors.DARK,
  variant = SkeletonVariants.RECTANGLE,
  className,
  animation = false,
  shadow = false,
  width,
  roundedCorner,
}) => (
  <div
    className={classNames(
      'skeleton-container',
      `skeleton-container--color-${color}`,
      `skeleton-container--variant-${variant}`,
      {
        'skeleton-container--with-animation': animation,
        'skeleton-container--with-shadow': shadow,
        [`skeleton-container--border-radius-${roundedCorner}`]: roundedCorner,
      },
      className,
    )}
    style={{ width }}
  >
    {children}
  </div>
);
