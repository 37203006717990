import { useRef, useCallback, useState } from 'react';

import debounce from 'lodash/debounce';

type UseObserverOptions = {
  enabled?: boolean;
  observerConfig?: IntersectionObserverInit;
};

const useObserver = ({
  enabled = true,
  observerConfig = {},
}: UseObserverOptions = {}) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const elements = useRef<Array<HTMLElement>>([]);
  const realTimeElementsInViewport = useRef<Array<number>>([]);
  const [elementsInViewport, setElementsInViewport] = useState<Array<number>>(
    [],
  );

  const initObserver = useCallback(() => {
    const updateState = debounce(
      () => {
        setElementsInViewport(Array.from(realTimeElementsInViewport.current));
      },
      500,
      {
        leading: false,
        trailing: true,
      },
    );

    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        const elementIndex = parseInt(
          (entry.target as HTMLElement).dataset.index!,
          10,
        );

        if (entry.isIntersecting) {
          realTimeElementsInViewport.current.push(elementIndex);
          realTimeElementsInViewport.current.sort((a: number, b: number) => {
            if (a < b) {
              return -1;
            }

            if (a > b) {
              return 1;
            }

            return 0;
          });
        } else {
          const index =
            realTimeElementsInViewport.current.indexOf(elementIndex);

          if (index !== -1) {
            realTimeElementsInViewport.current.splice(index, 1);
          }
        }
      });

      updateState();
    }, observerConfig);

    elements.current.forEach((element) => {
      observer.current?.observe(element);
    });
  }, []);

  const observerDisconnect = useCallback(() => {
    observer.current?.disconnect();
  }, []);

  const handleMount = useCallback((element: HTMLElement) => {
    if (observer.current) {
      observer.current.observe(element);
    } else {
      elements.current.push(element);
    }
  }, []);

  const handleUnmount = useCallback((element: HTMLElement) => {
    if (observer.current) {
      observer.current.unobserve(element);
    }
  }, []);

  if (enabled) {
    return {
      observer,
      elementsInViewport,
      initObserver,
      observerDisconnect,
      handleMount,
      handleUnmount,
    };
  }

  return {
    observer,
    elementsInViewport,
  };
};

export default useObserver;
