import type { FeedActions } from '../feed.actionsTypes';
import type { ActionHandler } from '../feed.types';

export const previewReadyToPlay: ActionHandler<
  FeedActions.PREVIEW_READY_TO_PLAY
> = (state, action) => {
  const preloadedIndexes = [...state.preloadedIndexes, action.inFeedIndex];

  return {
    ...state,
    preloadedIndexes,
    isPreloadBlocked:
      state.preloadPriorityIndex != null &&
      !preloadedIndexes.includes(state.preloadPriorityIndex),
  };
};
