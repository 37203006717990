import type { FC } from 'react';
import type { BannerProps } from './Banner.types';

import classnames from 'classnames';

import { Typography } from '../Typography/Typography';

export const Banner: FC<BannerProps> = ({
  backgroundImg,
  className,
  title,
}) => (
  <div
    className={classnames(className, 'banner-content__container')}
    style={{ backgroundImage: `url("${backgroundImg.props.url}")` }}
  >
    <Typography className="banner-content__text" {...title.props} />
  </div>
);
