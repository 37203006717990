import type { ComponentMapperProps } from './ComponentMapper.types';

export const ComponentMapper: React.FC<ComponentMapperProps> = ({
  type,
  props,
  components,
  tracks,
  ...otherProps
}) => {
  const Component = components[type];

  if (!Component) {
    return null;
  }

  return <Component tracks={tracks} {...props} {...otherProps} />;
};
