import type { PillProps } from './Pill.types';

import { Pill as PillAndes } from '@andes/badge';

export const Pill: React.FC<PillProps> = ({
  label,
  color,
  textColor,
  type,
  ...props
}) => (
  <PillAndes
    {...props}
    secondaryColor={{
      background: color || '',
      text: textColor,
    }}
  >
    <span>{label}</span>
  </PillAndes>
);
