import type { KnownComponent } from '../../../types/components';
import type { IconName } from '../Icon/Icon.type';

export enum PillBorder {
  STANDARD = 'standard',
  CORNER = 'corner',
  ROUNDED = 'rounded',
}

export enum PillType {
  NEUTRAL = 'neutral',
  GENERIC = 'generic',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export type PillTagProps = {
  label: string;
  hierarchy?: 'loud' | 'quiet';
  border?: PillBorder;
  size?: 'large' | 'small';
  bgColor?: string;
  color?: string;
  leftContent?: KnownComponent;
  rightContent?: KnownComponent;
  shape?: string;
  style?: string;
  icon?: IconName;
  type?: PillType;
  className?: string;
  borderColor?: string;
};
