import Card, { CardContent } from '@andes/card';

import { Skeleton } from '../Skeleton';
import { SkeletonColors } from '../Skeleton.types';

export const FeedSkeletonWebview = () => (
  <div className="feed-skeleton">
    {Array.from({ length: 3 }, (_, index) => (
      <div className="mediacard-skeleton" key={index}>
        <Card className="mediacard-wrapper-skeleton" shadow="elevated">
          <Skeleton
            color={SkeletonColors.LIGHT}
            className="mediacard-skeleton__header"
            animation
          />
          <CardContent>
            <div className="mediacard-skeleton__body">
              <div className="mediacard-skeleton__texts">
                <Skeleton
                  roundedCorner="s"
                  color={SkeletonColors.DARK}
                  animation
                  width="50%"
                />
                <Skeleton
                  roundedCorner="s"
                  className="skeleton--border-radius-s"
                  color={SkeletonColors.DARK}
                  animation
                />
              </div>
              <div className="mediacard-skeleton__content-tag-list">
                <Skeleton
                  roundedCorner="s"
                  color={SkeletonColors.LIGHT}
                  animation
                  width="20px"
                />
                <Skeleton
                  roundedCorner="s"
                  color={SkeletonColors.LIGHT}
                  animation
                  width="40px"
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    ))}
  </div>
);
