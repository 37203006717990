import type { TypographyApiProps } from '../../../../components/Typography/desktop';

import { useContext } from 'react';

import { i18nContext } from '../../../../context';
import { Typography as TypographyBase } from '../../../../components/Typography/desktop';

export const Typography = ({ label, ...props }: TypographyApiProps) => {
  const { get } = useContext(i18nContext) || props.i18nProps;

  return <TypographyBase component="p" label={get(label)} {...props} />;
};
