import type { MelidataTrack } from './melidataProvider.types';

import snakecaseKeys from 'snakecase-keys';

export const getMergedData = (
  melidataTrack: MelidataTrack,
  additionalData?: Record<string, unknown>,
) =>
  melidataTrack.event_data || additionalData
    ? {
        ...melidataTrack.event_data,
        ...snakecaseKeys(additionalData ?? {}, { deep: true }),
      }
    : undefined;
