import type { FC } from 'react';
import type { TypographyProps } from '../Typography.types';

import { Typography as TypographyBase } from '../Typography';

export const Typography: FC<TypographyProps> = ({
  size = 'xs',
  type = 'title',
  ...props
}) => <TypographyBase size={size} type={type} {...props} />;
