import type { FC } from 'react';
import type { ProgressIndicatorProps } from './ProgressIndicator.type';

import ProgressIndicatorLinear from '@andes/progress-indicator-linear';
import classnames from 'classnames';

export const ProgressIndicator: FC<ProgressIndicatorProps> = ({
  className,
  currentPercentage,
  color,
}) => (
  <ProgressIndicatorLinear
    className={classnames('progress-indicator', className)}
    value={currentPercentage}
    color={color}
  />
);
