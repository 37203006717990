import type { RealTimeTrackEvent } from '../realtime.types';

import { restClient } from '../../../../../common/rest-client';
import api from '../../../../../constants/api.json';

const { PATH_API } = api;

export async function sendRealtimeEvent(event: RealTimeTrackEvent) {
  return restClient.post(`${PATH_API.REALTIME}/track`, {
    data: event,
  });
}
