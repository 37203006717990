import type { MetaDataProps } from './Metadata.types';

import { useContext } from 'react';
import { type FC } from 'react';

import Head from 'nordic/head';
import { Script } from 'nordic/script';

import { i18nContext } from '../../../../context';

export const MetaData: FC<MetaDataProps> = (props) => {
  const { title, metas, description, seo, keywords } = props;
  const { get } = useContext(i18nContext);

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://imasdk.googleapis.com" />
        <link rel="preconnect" href="https://mdstrm.com" />
        <link rel="preconnect" href="https://pagead2.googlesyndication.com" />
        <link rel="preconnect" href="https://play.mlstatic.com" />
        <link rel="preconnect" href="https://licensing.bitmovin.com" />
        {metas?.map((meta, index) => {
          const key = `${meta.name}-${index}`;

          return <meta key={key} {...meta} />;
        })}
      </Head>

      <Head key={Date.now()}>
        {title && <title>{get(title)}</title>}
        {description && <meta name="description" content={get(description)} />}
        {keywords && <meta name="keywords" content={keywords} />}

        {/* Canonical URL defines the oficial URL to be indexed by crawlers */}
        {seo?.canonicalAbsoluteUrl && (
          <link rel="canonical" href={seo.canonicalAbsoluteUrl} />
        )}

        {/* Defines if this page is indexable by search engines */}
        {seo?.indexable != null && (
          <meta
            name="robots"
            content={seo.indexable ? 'index, follow' : 'noindex, follow'}
          />
        )}

        {/* Defines Schema.org Structured Data for better SEO */}
        {seo?.structuredData && (
          <Script type="application/ld+json">
            {JSON.stringify(seo.structuredData, null, 2)}
          </Script>
        )}

        {/* Enhances link preview on social platforms (facebook, twitter, slack, whatsapp, etc) */}
        {seo?.socialMetaTags?.map((tag, index) => {
          const key = `${tag.property || tag.name}-${index}`;

          return <meta key={key} {...tag} />;
        })}

        {/* Defines the alternate lang-country versions of the current page (ie. same page but in MLA, MLC, MLM, etc) */}
        {seo?.hrefLangs?.map((tag, index) => {
          const key = `hreflang-${index}`;

          return <link key={key} {...tag} />;
        })}
      </Head>
    </>
  );
};
