import type { ButtonRefreshProps } from './ButtonRefresh.types';

import { useCallback, useContext } from 'react';

import { i18nContext } from '../../../../context';
import { Button } from '../../../../components/Button/mobile';

export const ButtonRefresh = ({ label = '', ...props }: ButtonRefreshProps) => {
  const { get } = useContext(i18nContext);

  const onClick = useCallback(() => {
    window.location.reload();
  }, []);

  return <Button label={get(label)} onClick={onClick} {...props} />;
};
