import type { FeedActions } from '../feed.actionsTypes';
import type { ActionHandler } from '../feed.types';

export const previewPlayerToggleAudio: ActionHandler<
  FeedActions.PREVIEW_PLAYER_TOGGLE_AUDIO
> = (state) => ({
  ...state,
  previewPlayer: {
    isMuted: !state.previewPlayer.isMuted,
  },
});
