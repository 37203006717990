import type { FC } from 'react';
import type { TagProps } from '../../Tag.types';

export const SquareTag: FC<TagProps> = ({ bgColor, className, label }) => (
  <div className={className}>
    <div
      className="square-tag"
      style={{
        backgroundColor: bgColor,
      }}
    >
      {label}
    </div>
  </div>
);
