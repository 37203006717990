import type { FunctionComponent } from 'react';
import type { ImageProps } from '../Image.types';

import { Image as ImageNordic } from 'nordic/image';
import classNames from 'classnames';

import commons from '../../../constants/commons.json';

export const Image: FunctionComponent<ImageProps> = ({
  title,
  url,
  className,
  preload,
  ratio = 'auto',
  align = 'center',
  srcFallback = commons.PLACEHOLDER_IMG,
  lazy = 'on',
  mounted = false,
  carousel = false,
}) => (
  <ImageNordic
    mounted={mounted}
    carousel={carousel}
    src={url}
    alt={title}
    className={classNames(className, {
      [`image--ratio-${ratio}`]: ratio !== 'auto',
      [`image--align-${align}`]: align !== 'center',
    })}
    lazyload={lazy}
    preload={preload}
    srcFallback={srcFallback}
  />
);
