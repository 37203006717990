import type { RealTimeTrackEvent } from './realtime.types';

import { LoggerFactory } from 'nordic/logger';
import snakecaseKeys from 'snakecase-keys';
import merge from 'lodash/merge';

import { sendRealtimeEvent } from './repositories';

const log = LoggerFactory('mplay-melidataProvider');

export const realtimeProvider = async (
  realtimeTrack: RealTimeTrackEvent,
  additionalData?: Record<string, unknown>,
  validateEnvironment?: boolean,
) => {
  try {
    const { event_data: eventDataOriginalTrack } = realtimeTrack;
    const eventData = merge(eventDataOriginalTrack, additionalData);
    const bodyTrack = {
      ...realtimeTrack,
      ...snakecaseKeys({ eventData }, { deep: true }),
    };

    if (!validateEnvironment) {
      await sendRealtimeEvent(bodyTrack);
    } else {
      // eslint-disable-next-line no-console
      console.info('%c[track][realtime]', 'color:#3498db', bodyTrack);
    }
  } catch (e) {
    if (e instanceof Error) {
      log.error(e.toString());
    }
  }
};
