import { useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import queryParams from '../../constants/query-params.json';

export const useClearOriginParams = () => {
  const location = useLocation();
  const history = useHistory();

  function removeOriginParams() {
    const searchParams = new URLSearchParams(location.search);
    const requestedQueryParams = queryParams.map(({ name }) => name);
    const filteredParams: Record<string, string> = {};

    searchParams.forEach((_value, key) => {
      if (!requestedQueryParams.some((findKey) => key.startsWith(findKey))) {
        const value = searchParams.get(key);

        if (value !== null) {
          filteredParams[String(key)] = value;
        }
      }
    });

    const filteredSearch = new URLSearchParams(filteredParams).toString();

    location.search = filteredSearch;
    history.replace(`${location.pathname}?${filteredSearch}`, location.state);
  }

  useEffect(() => {
    if (location.search) {
      removeOriginParams();
    }
  }, [location.pathname]);
};
