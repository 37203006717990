import type { FC, SVGProps } from 'react';

export const PlayIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <circle
        cx={18}
        cy={18}
        r={16.75}
        fill="#000"
        stroke="#fff"
        strokeWidth={1.5}
      />
      <path d="M12 27.7V8.5l16 9.6-16 9.6Z" fill="#fff" />
    </g>
  </svg>
);
