import type { FC, SVGProps } from 'react';

export const PlayAssetFrame: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 14.4904V2.49039L13.5 8.49039L3.5 14.4904Z"
      stroke="white"
      strokeWidth="1.2"
    />
  </svg>
);
