import type { FC } from 'react';
import type { SplashScreenData } from '../../../services/utils/validate-origin/validate-origin.type';
import type { Tracks } from '../../utils/track';

import { useEffect, useState } from 'react';

import { ProviderTrackEventName, track } from '../../utils/track';
import { SplashScreen } from '../SplashScreen';

type SplashContainerType = {
  data: SplashScreenData;
  tracks?: Tracks;
  siteId: string;
};

export const SplashScreenContainer: FC<SplashContainerType> = ({
  data,
  tracks,
  siteId,
}) => {
  const [isVisible, setIsVisible] = useState(!!data.displaySecond);

  useEffect(() => {
    if (!data.displaySecond) {
      return () => {};
    }

    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, data.displaySecond * 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (isVisible) {
      track(tracks, ProviderTrackEventName.DISPLAY);
    }
  }, [tracks]);

  return isVisible ? <SplashScreen {...data} siteId={siteId} /> : null;
};
