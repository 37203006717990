import { useMemo } from 'react';

import { Link as BaseLink } from 'frontend-spa';

export const Link: typeof BaseLink = ({ to, ...props }) => {
  const parsedTo = useMemo(() => {
    if (typeof to !== 'object') {
      return to;
    }

    const pathSearchSegments = to.pathname?.split('?') ?? [];

    return {
      ...to,
      pathname: pathSearchSegments[0],
      search:
        pathSearchSegments.length > 1 ? `?${pathSearchSegments[1]}` : undefined,
    };
  }, [to]);

  return <BaseLink {...props} to={parsedTo} />;
};
