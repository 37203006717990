import type { FC, SVGProps } from 'react';

export const OvalIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
    {...props}
  >
    <ellipse cx="2" cy="2.49992" rx="2" ry="2.00554" fill="white" />
  </svg>
);
