import type { FeedActions } from '../feed.actionsTypes';
import type { ActionHandler } from '../feed.types';

export const setInViewport: ActionHandler<FeedActions.SET_IN_VIEWPORT> = (
  state,
  action,
) => ({
  ...state,
  inViewportIndexes: action.inViewportIndexes,
  preloadedIndexes: state.preloadedIndexes.filter((index) =>
    action.inViewportIndexes.includes(index),
  ),
});
