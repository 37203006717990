import type { FC, PropsWithChildren } from 'react';
import type { PageProps } from './Page.types';

import { Script } from 'nordic/script';
import { Page as NordicPage } from 'nordic/page';

import { MetaData } from './components/MetaData';
import { PageProvider } from './context/PageContext';
import commons from '../../constants/commons.json';

const { CONTENT_SEARCH_SRC } = commons;

export const Page: FC<PropsWithChildren<PageProps>> = ({
  children,
  device,
  headerOptions,
  keyword,
  name,
  view,
  translations,
  view: { tracks, metadata } = {},
  platform,
  error,
  scripts = true,
  deviceType,
}) => {
  const stateProps = {
    view,
    device,
    name,
    headerOptions,
    keyword,
    platform,
    error,
    translations,
    deviceType,
  };

  return (
    <NordicPage
      name={name}
      melidata={tracks?.display?.melidata}
      state={stateProps}
      scripts={scripts}
      deviceType={deviceType}
    >
      <MetaData {...metadata} />

      {scripts ? (
        <>
          <Script src="framework.js" priority={11} />
          <Script src="vendor.js" priority={11} />
          <Script src="andes.js" priority={11} />
          <Script id="search-box" src={CONTENT_SEARCH_SRC} priority={12} />
        </>
      ) : null}

      <PageProvider configurations={view.configurations}>
        {children}
      </PageProvider>
    </NordicPage>
  );
};
