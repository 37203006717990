import type { FC, ReactNode } from 'react';
import type { TranslationsDictionary } from './i18n.types';

import { createContext, useCallback } from 'react';

import { useI18n } from 'nordic/i18n';

import { resolveTranslations } from './i18n.translations';
import { Utils } from '../../utils';

export const i18nContext = createContext<TranslationsDictionary>(
  {} as TranslationsDictionary,
);

export interface I18nContextType {
  get: (key: string) => string;
}

interface I18nProviderProps {
  i18nProps?: I18nContextType;
  children: ReactNode | undefined;
}

export const I18nProvider: FC<I18nProviderProps> = ({
  children,
  i18nProps,
}) => {
  const { i18n } = useI18n();
  const contextValue = i18n || i18nProps;
  const translations = resolveTranslations(contextValue);
  const get = useCallback(
    (key: string) => Utils.get(translations, key, key),
    [],
  );

  return (
    <i18nContext.Provider value={{ ...translations, get }}>
      {children}
    </i18nContext.Provider>
  );
};
