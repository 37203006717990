import type { FC } from 'react';
import type { FeedProps } from './Feed.types';

import { useEffect } from 'react';

import { FeedProvider } from './context';
import useObserver from './hook/useObserver';
import { FeedList } from './components/FeedList';

export const Feed: FC<FeedProps> = (props) => {
  const { detectActiveElement = false } = props;
  const {
    elementsInViewport,
    handleMount,
    handleUnmount,
    initObserver,
    observerDisconnect,
  } = useObserver({
    enabled: detectActiveElement,
    observerConfig: {
      threshold: 0.5,
    },
  });

  useEffect(() => {
    initObserver?.();

    return () => {
      observerDisconnect?.();
    };
  }, []);

  return (
    <FeedProvider inViewportIndexes={elementsInViewport}>
      <FeedList
        {...props}
        onMount={handleMount}
        onBeforeUnmount={handleUnmount}
      />
    </FeedProvider>
  );
};
