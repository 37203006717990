import type { FC, SVGProps } from 'react';

export const PlayKeepWatching: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <circle cx="7" cy="7.44104" r="7" fill="#737373" fillOpacity="0.5" />
      <path
        d="M4.375 10.8017V4.16789C4.375 3.77924 4.79899 3.53918 5.13225 3.73914L10.6604 7.05604C10.9841 7.25025 10.9841 7.71934 10.6604 7.91354L5.13225 11.2304C4.79899 11.4304 4.375 11.1903 4.375 10.8017Z"
        fill="#fff"
      />
    </g>
  </svg>
);
