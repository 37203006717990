import type { FC } from 'react';
import type { FeedProps } from '../../Feed.types';

import { memo, useMemo } from 'react';

import ScrollPaginator from 'react-infinite-scroll-component';
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';
import classNames from 'classnames';

import commons from '../../../../constants/commons.json';
import { FeedItem } from '../FeedItem';
import { TypeKnownComponent } from '../../../../../types/components';
import { ComponentMapper } from '../../../ComponentMapper';
import { FeedType } from '../../Feed.types';
import { FeedSkeletonWebview } from '../../../Skeleton/components/FeedSkeletonWebview';

const { DEFAULT_SCROLL_THRESHOLD } = commons;
const FEED_COMPONENT = 'feed-component';

const onNextPageDefault = () => null;

const isActivable = (
  activableTypes: Array<TypeKnownComponent> | undefined,
  type: TypeKnownComponent,
) => !activableTypes || activableTypes.includes(type);

function getIfActivable<T>(activable: boolean, value: T): T | undefined {
  return activable ? value : undefined;
}

export const FeedList: FC<FeedProps> = memo(
  ({
    className,
    components,
    componentsList,
    scrollableTarget = FEED_COMPONENT,
    scrollThreshold = DEFAULT_SCROLL_THRESHOLD,
    type = FeedType.GRID,
    onScroll,
    onNextPage = onNextPageDefault,
    isLoading = false,
    hasMore = false,
    onMount,
    onBeforeUnmount,
    activableTypes,
    isWebview,
  }) => {
    const resultComponents = useMemo(
      () =>
        components.filter(({ type }) =>
          Object.keys(componentsList).includes(type),
        ),
      [components, componentsList],
    );

    const handleScroll = () => {
      onScroll?.();
    };

    return (
      <section
        id={FEED_COMPONENT}
        className={classNames(FEED_COMPONENT, type, className)}
        data-testid={FEED_COMPONENT}
        onScroll={handleScroll}
      >
        <ScrollPaginator
          dataLength={resultComponents.length}
          next={onNextPage}
          hasMore={hasMore}
          scrollThreshold={scrollThreshold}
          scrollableTarget={scrollableTarget}
          loader={
            isLoading && isWebview ? (
              <FeedSkeletonWebview />
            ) : (
              <div className="feed__spinner-container">
                <ProgressIndicatorCircular
                  size="large"
                  className="feed__spinner--gray"
                />
              </div>
            )
          }
        >
          {resultComponents.map((component, index) => {
            const key = `${component.type}-${index}`;

            if (component.type === TypeKnownComponent.CAROUSEL) {
              const Component = componentsList[TypeKnownComponent.CAROUSEL];

              if (!Component) {
                return null;
              }

              return <Component key={key} {...component.props} />;
            }

            const activable = isActivable(activableTypes, component.type);

            return (
              <FeedItem
                key={key}
                index={index}
                onMount={getIfActivable(activable, onMount)}
                onBeforeUnmount={getIfActivable(activable, onBeforeUnmount)}
                className={`feed__item--${component.type}`}
              >
                <ComponentMapper
                  {...component}
                  components={componentsList}
                  indexInFeed={index}
                />
              </FeedItem>
            );
          })}
        </ScrollPaginator>
      </section>
    );
  },
);

FeedList.displayName = 'FeedList';
