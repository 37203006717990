import type {
  MelidataFunction,
  MelidataTrack,
  DataTrack,
} from './melidataProvider.types';

import { LoggerFactory } from 'nordic/logger';
import snakecaseKeys from 'snakecase-keys';

import { getMergedData } from './getMergedData';

const log = LoggerFactory('mplay-melidataProvider');

declare const melidata: MelidataFunction;

export const melidataProvider = (
  melidataTrack: MelidataTrack,
  additionalData?: Record<string, unknown>,
  validateEnvironment?: boolean,
) => {
  try {
    if (typeof melidata !== 'function') {
      throw new Error('melidata function is undefined');
    }

    const dataTrack: DataTrack = {
      path: melidataTrack.path,
      data: getMergedData(melidataTrack, snakecaseKeys({ ...additionalData })),
    };

    if ('experiments' in melidataTrack) {
      dataTrack.experiments = melidataTrack.experiments;
    }

    if (!validateEnvironment) {
      melidata('send', melidataTrack.type, dataTrack);
    } else {
      // eslint-disable-next-line no-console
      console.info('%c[track][melidata]', 'color:#3498db', dataTrack);
    }
  } catch (e) {
    if (e instanceof Error) {
      log.error(e.toString());
    }
  }
};
