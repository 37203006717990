import type { FC } from 'react';
import type { TagProps } from '../Tag.types';

import classnames from 'classnames';
import TagAndes from '@andes/tag';

import { SquareTag } from '../components/SquareTag/SquareTag';
import { Icon } from '../../Icon';

export const Tag: FC<TagProps> = (props) => {
  if ('shape' in props) {
    return <SquareTag {...props} />;
  }

  const { leftContent, bgColor, className, style, ...otherProps } = props;

  let icon;

  const classNameTag = classnames('tag', className, {
    'tag--andes-bg-secondary': bgColor === undefined,
    'tag--bold': style === 'bold',
  });

  if (leftContent && leftContent.type === 'icon') {
    icon = { icon: <Icon {...leftContent} /> };
  }

  return (
    <div style={{ background: bgColor }} className={classNameTag}>
      <TagAndes {...otherProps} avatar={icon} />
    </div>
  );
};
