import type { IFilterXSSOptions } from 'xss';
import type {
  TypographyProps,
  TypographyColor,
  TypographySize,
  TypographyComponent,
} from './Typography.types';
import type { TitleProps } from '@andes/typography';

import { useMemo } from 'react';

import { Typography as TypographyAndes, Title } from '@andes/typography';
import classNames from 'classnames';
import xss from 'xss';
import parser from 'html-react-parser';

type TitleComponent = TitleProps['component'];

const defaultOptionsXss = {
  whiteList: {
    b: [],
    u: [],
    br: [],
  },
};

const TITLE_COMPONENTS: Record<string, TitleComponent> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

const getTitleComponent = (component?: TypographyComponent) =>
  typeof component === 'string' ? TITLE_COMPONENTS[component] : 'h4';

const getColor = (color: TypographyColor) => {
  // We need to convert "white" to "inverted" because Andes does not support "white" as a color as we're receiving from the backend
  if (color === 'white') {
    return 'inverted';
  }

  return color;
};

const getSize = (size: TypographySize) => {
  // We need to convert "xl" to "xs" because Andes does not support "xl" as a size as we're receiving from the backend
  if (size === 'xl') {
    return 'xs';
  }

  return size;
};

const getMaxLines = (maxLines: number) => {
  if (!maxLines) {
    return {};
  }

  return { WebkitLineClamp: maxLines, lineClamp: maxLines };
};

export const Typography = ({
  size = 'xs',
  label,
  maxLines = 0,
  className,
  unit = 'px',
  optionsXSS = defaultOptionsXss,
  color = 'primary',
  type,
  component,
  ...props
}: TypographyProps) => {
  const options: IFilterXSSOptions = optionsXSS;
  const sanitizedLabel = useMemo(
    () => parser(xss(label, options)),
    [label, options],
  );

  const commonProps = useMemo(
    () => ({
      color: getColor(color),
      size: getSize(size),
      style: getMaxLines(maxLines),
      className: classNames(className, {
        [`typography-wrapper--unit-${unit}`]: unit !== 'px',
        'typography-wrapper--has-max-lines': maxLines > 0,
        [`typography-wrapper--custom-color-${color}`]: color === 'white',
      }),
    }),
    [className, color, maxLines, size, unit],
  );

  if (type === 'title') {
    return (
      <Title
        component={getTitleComponent(component)}
        {...commonProps}
        {...props}
      >
        {sanitizedLabel}
      </Title>
    );
  }

  return (
    <TypographyAndes
      type={type}
      component={component || 'span'}
      {...commonProps}
      {...props}
    >
      {sanitizedLabel}
    </TypographyAndes>
  );
};
