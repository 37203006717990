import type { FC } from 'react';
import type { ErrorProps } from './ErrorContainer.types';

import { TypeKnownComponent } from '../../../types/components';
import { ComponentList } from '../../components/ComponentList';
import { Typography, Button, Image } from './components';

const knownComponents = {
  [TypeKnownComponent.BUTTON]: Button,
  [TypeKnownComponent.IMAGE]: Image,
  [TypeKnownComponent.TYPOGRAPHY]: Typography,
};

export const ErrorContainer: FC<ErrorProps> = ({ components, className }) => {
  if (!components) {
    return null;
  }

  return (
    <section className={className}>
      <ComponentList
        knownComponents={knownComponents}
        components={components}
      />
    </section>
  );
};
