import type { FC } from 'react';
import type { IconProps } from './Icon.type';

import StarFilled32 from '@andes/icons/StarFilled32';
import VisibilityOFF24 from '@andes/icons/VisibilityOFF24';
import VolumeOff24 from '@andes/icons/VolumeOff24';
import VolumeOn24 from '@andes/icons/VolumeOn24';
import Settings24 from '@andes/icons/Settings24';
import Close20 from '@andes/icons/Close20';
import ArrowLeft20 from '@andes/icons/ArrowLeft20';
import OverflowMenuVertical16 from '@andes/icons/OverflowMenuVertical16';
import DeleteIcon from '@andes/icons/Delete16';
import InfoIcon from '@andes/icons/Info16';
import Drawer24 from '@andes/icons/Drawer24';
import Copy24 from '@andes/icons/Copy24';
import { PlayIcon } from './custom/PlayIcon';
import { RelevantIcon } from './custom/RelevantIcon';
import { OvalIcon } from './custom/OvalIcon';
import { PlayKeepWatching } from './custom/PlayKeepWatching';
import { StartOver } from './custom/StartOver';
import { PlayAssetFrame } from './custom/PlayAssetFrame';

export const icon = {
  visibility_off: VisibilityOFF24,
  star: StarFilled32,
  volumeOff: VolumeOff24,
  settings: Settings24,
  close: Close20,
  backbutton: ArrowLeft20,
  playerVolumeOn: VolumeOn24,
  mplay_play: PlayIcon,
  VisibilityOFF24,
  view_relevant: RelevantIcon,
  mplay_oval: OvalIcon,
  mplay_keepwatching_play: PlayKeepWatching,
  OverflowMenuVertical16,
  mplay_delete: DeleteIcon,
  mplay_information: InfoIcon,
  mplay_overflow_vertical_menu: OverflowMenuVertical16,
  start_over: StartOver,
  play_asset_frame: PlayAssetFrame,
  info: Drawer24,
  copy: Copy24,
};

export const Icon: FC<IconProps> = ({ content, className, color }) => {
  const Icon = icon[content];

  if (Icon) {
    return <Icon color={color} className={className} />;
  }

  return null;
};
