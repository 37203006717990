import type { FC, SVGProps } from 'react';

export const StartOver: FC<SVGProps<SVGSVGElement>> = () => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.75 0.25C15.5328 0.25 8.19857 5.49692 4.44231 13.9338V6.91667H0.75V20.5577H13.0468V16.8654H7.23128C10.2206 8.84966 16.5791 3.94231 24.75 3.94231C35.9656 3.94231 45.0577 13.0344 45.0577 24.25C45.0577 35.4656 35.9656 44.5577 24.75 44.5577C14.7953 44.5577 6.51349 37.3951 4.77715 27.9423H1.03226C2.80828 39.4442 12.7508 48.25 24.75 48.25C38.0048 48.25 48.75 37.5048 48.75 24.25C48.75 10.9952 38.0048 0.25 24.75 0.25Z"
      fill="white"
    />
  </svg>
);
