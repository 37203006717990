import type { FC, PropsWithChildren } from 'react';
import type { ViewConfiguration } from '../Page.types';

import { createContext } from 'react';

import { useStaticData } from '../../../context';

const defaultValue = {
  bitmovinLicense: '',
  bitmovinAnalyticsLicense: '',
};

export const PageContext = createContext<ViewConfiguration>(defaultValue);

export const PageProvider: FC<
  PropsWithChildren<{
    configurations?: ViewConfiguration;
  }>
> = ({ children, configurations = {} }) => {
  const { scope } = useStaticData();

  return (
    <PageContext.Provider value={configurations}>
      {scope && <div className="container__scope-badge">{scope}</div>}
      {children}
    </PageContext.Provider>
  );
};
