import type { ComponentListProps } from './ComponentList.types';

import { ComponentMapper } from '../ComponentMapper';

export const ComponentList: React.FC<ComponentListProps> = ({
  components,
  knownComponents,
  tracks,
}) => (
  <>
    {components.map((component, index) => {
      const key = `${component.type}-${index}`;

      return (
        <ComponentMapper
          key={key}
          components={knownComponents}
          tracks={tracks}
          {...component}
        />
      );
    })}
  </>
);
