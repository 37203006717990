import type { FeedActions } from '../feed.actionsTypes';
import type { ActionHandler } from '../feed.types';

export const setPriorityPreview: ActionHandler<
  FeedActions.SET_PRIORITY_PREVIEW
> = (state, action) => ({
  ...state,
  preloadPriorityIndex: action.inFeedIndex,
  isPreloadBlocked:
    action.inFeedIndex != null &&
    !state.preloadedIndexes.includes(action.inFeedIndex),
});
